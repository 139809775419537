import React, { FC, useState, useEffect } from "react";
import { TableRowInput, TableRowDropdown } from "../Common";
import { DisplaySettings as Model, ScreenSaver, DisplayTemperatureType, TemperatureUnit, BoolType, ClockFormat, ScrollSpeed } from "../../store/UWG5NventStore/models";

export interface DisplaySettingsProps {
    displaySettings: Model,
    saveDisplaySettings: (newDisplaySettings: Model) => void
}

export const DisplaySettings: FC<DisplaySettingsProps> = (props) => {
    const [displaySettings, setDisplaySettings] = useState(props.displaySettings);

    const save = () => props.saveDisplaySettings(displaySettings);
    useEffect(() => {
        setDisplaySettings(props.displaySettings);
    }, [props.displaySettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Active Screen Brightness"
                    currentValue={displaySettings.brightnessNormal}
                    originalValue={props.displaySettings.brightnessNormal}
                    inputType="number"
                    minValue={1}
                    maxValue={6}
                    onSave={save}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, brightnessNormal: Number(newValue) })} />

                <TableRowInput
                    name="Screensaver Brightness"
                    currentValue={displaySettings.brightnessScreensaver}
                    originalValue={props.displaySettings.brightnessScreensaver}
                    inputType="number"
                    minValue={1}
                    maxValue={6}
                    onSave={save}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, brightnessScreensaver: Number(newValue) })} />

                <TableRowDropdown
                    name="Display"
                    currentValue={Number(displaySettings.displayOn)}
                    originalValue={Number(props.displaySettings.displayOn)}
                    type={BoolType}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, displayOn: !!newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Screenlock"
                    currentValue={Number(displaySettings.screenLockEnabled)}
                    originalValue={Number(props.displaySettings.screenLockEnabled)}
                    type={BoolType}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, screenLockEnabled: !!newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Screensaver Type"
                    currentValue={displaySettings.screensaverType}
                    originalValue={props.displaySettings.screensaverType}
                    type={ScreenSaver}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, screensaverType: newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Display Temperature Type"
                    currentValue={displaySettings.displayTemperatureType}
                    originalValue={props.displaySettings.displayTemperatureType}
                    type={DisplayTemperatureType}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, displayTemperatureType: newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Temperature Unit"
                    currentValue={displaySettings.temperatureUnit}
                    originalValue={props.displaySettings.temperatureUnit}
                    type={TemperatureUnit}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, temperatureUnit: newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Show Minutes Only"
                    currentValue={Number(displaySettings.showMinutesOnly)}
                    originalValue={Number(props.displaySettings.showMinutesOnly)}
                    type={BoolType}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, showMinutesOnly: !!newValue })}
                    onSave={save} />

                <TableRowInput
                    name="Time Between Scrolling"
                    currentValue={displaySettings.screensaverClockScrollingInterval}
                    originalValue={props.displaySettings.screensaverClockScrollingInterval}
                    inputType="number"
                    minValue={5}
                    maxValue={30}
                    onSave={save}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, screensaverClockScrollingInterval: Number(newValue) })} />

                <TableRowDropdown
                    name="Clock Format"
                    currentValue={displaySettings.screensaverClockTimeFormat}
                    originalValue={props.displaySettings.screensaverClockTimeFormat}
                    type={ClockFormat}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, screensaverClockTimeFormat: newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Scroll Speed"
                    currentValue={displaySettings.screensaverClockScrollingSpeed}
                    originalValue={props.displaySettings.screensaverClockScrollingSpeed}
                    type={ScrollSpeed}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, screensaverClockScrollingSpeed: newValue })}
                    onSave={save} />
            </tbody>
        </table>
    );
};
