export enum ModeType {
    Unknown = 0,
    Auto = 1,
    Manual = 2,
    Hold = 3
}

export enum FallbackMode {
    Unknown = 0,
    Auto = 1,
    Manual = 2
}

export interface StandbyState {
    isInStandby: boolean;
}

export interface Mode {
    currentMode: ModeType,
    fallbackMode: FallbackMode,
    manualSetpoint: number,
    holdUntilSetpoint: number | null,
    holdUntilEndTime: Date | null,
    holdIsPermanent: boolean | null
}

export enum DaysOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export enum Errors {
    NoErrors = 0,
    InternalFailure = 1,
    InternalSensorFailure = 2,
    ExternalWiredFloorSensorFailure = 4,
    InternalCompensationSensorFailure = 8,
    BootloaderError = 16,
    InternalOverheating = 32,
    CommunicationError = 64,
    GFCIError = 128
}

const errorDescriptionsMap = new Map<Errors, string>();
errorDescriptionsMap.set(Errors.InternalFailure, "E0 - Internal failure, the thermostat is defective");
errorDescriptionsMap.set(Errors.InternalSensorFailure, "E1 - Internal sensor defective or short-circuited");
errorDescriptionsMap.set(Errors.ExternalWiredFloorSensorFailure, "E2 - External wired floor sensor disconnected, defective or short-circuited");
errorDescriptionsMap.set(Errors.InternalCompensationSensorFailure, "E3 - \"Internal\" compensation sensor defective");
errorDescriptionsMap.set(Errors.BootloaderError, "E4 - Bootloader Error – Update Failed");
errorDescriptionsMap.set(Errors.InternalOverheating, "E5 - Internal Overheating");
errorDescriptionsMap.set(Errors.CommunicationError, "E6 - Communication Error – Wifi / BLE – WiFi chip bricked");
errorDescriptionsMap.set(Errors.GFCIError, "E7 - GFCI Error");

export const ErrorDescriptions = errorDescriptionsMap;

export enum TlsVersion {
    Default,
    Tls11,
    Tls12,
    Tls13
}

export type KeyValue<TValue> = {
    name: string
    value: TValue
}

export const TlsVersionsLookup: Array<KeyValue<TlsVersion>> = [
    {
        value: TlsVersion.Default,
        name: "Default"
    },
    {
        value: TlsVersion.Tls11,
        name: "TLS 1.1"
    },
    {
        value: TlsVersion.Tls12,
        name: "TLS 1.2"
    },
    {
        value: TlsVersion.Tls13,
        name: "TLS 1.3"
    }
];

export interface ScheduleEvent {
    index: number,
    setpointTemp: number,
    roomTemperature: number,
    startTimeM: number,
    endTimeM: number,
    enabled: boolean,
    isActive: boolean
}

export interface Schedule {
    baseSetpoint: number,
    scheduleTable: ScheduleEvent[]
}

export interface UWG5NventState {
    isHeatRelayActive: boolean;
    floorTemperature: number;
    roomTemperature: number;
    floorTemperatureFahrenheit: number;
    roomTemperatureFahrenheit: number;
    errorState: Errors;
    isOpenWindowDetected: boolean;
}

export interface CreateUWG5NventRequest {
    name: string;
    serialId: number | null;
    serverUrl: string;
    useSsl: boolean | null;
    minTlsVersion: TlsVersion;
    maxTlsVersion: TlsVersion;
    otpCode: string;
    accessCode: string;
    userId: string;
    distributorId: number | null;
}

export interface UWG5NventSettings {
    deviceInternalId: string,
    serverUrl: string;
    useSsl: boolean | null;
    minTlsVersion: TlsVersion;
    maxTlsVersion: TlsVersion;
    otpCode: string;
    accessCode: string;
    userId: string;
}

export interface UWG5NventThermostat {
    ID: number,
    thermostatId: string,
    isConnected: boolean,
    settings: UWG5NventSettings,
    deviceInfo: DeviceInfo,
    displaySettings: DisplaySettings,
    regulationSettings: RegulationSettings,
    heatingSettings: HeatingSettings,
    floorSensorSettings: FloorSensorSettings,
    awayModeSettings: AwayModeSettings,
    dateTimeSettings: DateTimeSettings,
    wifiSettings: WifiSettings,
    state: UWG5NventState,
    standbyState: StandbyState,
    mode: Mode;
    schedule: Schedule,
    warrantyInfo: WarrantyInfo,
    workspaceId: number,
    isTimerEnabled: boolean,
    reconnectEnabled: boolean,
    isHeatRelayActive: boolean
}

export interface LoadableThermostat {
    thermostatId: string;
    name: string;
    serialNumber: string;
    assignedTo: string;
    workspaceId: number;
}

export interface UWG5NventThermostats {
    thermostats: UWG5NventThermostat[],
    loadable: LoadableThermostat[],
    distributors: DistributorListItem[],
    users: UserAccountItem[]
}

export interface DeviceInfo {
    serialId: number,
    thermostatName: string,
    distributorId: number,
    thermostatSoftware: string,
    thermostatBootloaderSoftware: string,
    ubloxSerial: string,
    ubloxSoftware: string,
    mcuUuid: Uint8Array,
    hardwareVersionIdentifier: string,
    serialFlashType: string,
    protocolVersion: Uint8Array
}

export interface DisplaySettings {
    temperatureUnit: TemperatureUnit,
    screensaverType: ScreenSaver,
    displayTemperatureType: DisplayTemperatureType,
    screenLockEnabled: boolean,
    brightnessNormal: number,
    brightnessScreensaver: number,
    displayOn: boolean,
    showMinutesOnly: boolean,
    screensaverClockTimeFormat: ClockFormat,
    screensaverClockScrollingSpeed: ScrollSpeed,
    screensaverClockScrollingInterval: number
}

export interface HeatingSettings {
    floorLoad: number,
    floorSensorType: FloorSensorType,
    floorSensorOffset: number,
    roomSensorOffset: number,
    adaptiveEnabled: boolean,
    openWindowEnabled: boolean
}

export interface RegulationSettings {
    sensorApplication: SensorApplication,
    temperatureLimitMax: number,
    temperatureLimitMin: number,
    floorType: FloorType,
    floorProtMax: number,
    floorProtMin: number,
    frostProtTemp: number,
    frostProtEnabled: boolean
}

export interface FloorSensorSettings {
    value15Deg: number,
    value20Deg: number,
    value25Deg: number,
    value30Deg: number
}

export interface AwayModeSettings {
    startTime: Date | null,
    endTime: Date | null,
    temperatureLimitMax: number | null
}

export interface DateTimeSettings {
    dst: Dst,
    timezoneMinutes: number,
}

export interface WifiSettings {
    ssid: string,
    authentication: Authentication,
    defaultGateway: string,
    netmask: string,
    ipv4: string,
    ipv6: string,
    dhcp: boolean,
    macWifi: string,
    macBle: string,
    preferedWifiChannel: number,
    numberOfTimesWifiApConnectWasCalled: number,
    bleState: number
}

export interface WarrantyInfo {
    totalUptimeS: number,
    relayActiveS: number,
    relayClicks: number,
    overheatTimeS: number,
    maxInternalTemp: number,
    minInternalTemp: number,
    gfciCount: number,
    secSinceLastGfci: number,
}


export enum TemperatureUnit {
    Unknown = 0,
    Celsius = 1,
    Fahrenheit = 2
}

export enum ScreenSaver {
    Unknown = 0,
    Clock = 1,
    Temperature = 2
}

export enum DisplayTemperatureType {
    Unknown = 0,
    SetPointTemperature = 1,
    ActualTemperature = 2
}

export enum ClockFormat {
    Unknown = 0,
    TwelveHour = 1,
    TwentyFourHour = 2
}

export enum ScrollSpeed {
    Unknown = 0,
    Low = 1,
    Medium = 2,
    High = 3
}

export enum FloorSensorType {
    Unknown = 0,
    Type10kOhm = 1,
    Type12kOhm = 2,
    TypeCustom = 3
}

export enum FloorType {
    Unknown = 0,
    Laminate = 1,
    Tile = 2,
    Wood = 3,
    Custom = 4,
    Disabled = 5
}

export enum Dst {
    Unknown = 0,
    None = 1,
    NorthAmerica = 2,
    Europe = 3
}

export enum SensorApplication {
    Unknown = 0,
    Floor = 1,
    Room = 2,
    RoomWithFloor = 3,
    Sensorless = 4
}

export enum BoolType {
    On = 1,
    Off = 0
}

export enum Authentication {
    Unknown = 0,
    Open = 1,
    Wpa2 = 2
}

export interface DistributorListItem {
    id: string;
    distributorId: number;
    privateLabelId: string;
    privateLabelName: string;
    customerCode: string;
    description: string;
}

export interface UserAccountItem {
    id: string;
    username: string;
    email: string;
}